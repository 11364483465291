header{
    background: $gray-1000;
    background-image: url("../img/bg-metal.png");
    background-position: bottom left;
    background-repeat: repeat;
}
.header-top{
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.05);
    color: $white;
}
.header-top .container{
    display: flex;
}
.header-top__tel{
    display: flex;
    font-size: 14px;
    line-height: 1;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: center;
}
.header-top__tel small{
    display: flex;
    font-size: 14px;
}
.header-top__tel img{
    margin: 0 10px;
    width: 16px;
    height: 16px;
}
.header-top__tel span{
    font-size: 14px;
    font-weight: 500;
    padding-right: 10px;
}

.header-top__links{
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
}
.header-top__links a{
    font-size: 13px;
    margin: 0 0 0 20px;
    color: $white;
    text-transform: uppercase;
}
.header-top__links a:hover{
    color: $white;
    text-decoration: underline;
}
@media screen and (max-width: 991px){
    .header-top{
        display: none;
    }
}


.header-main{
    padding: 20px 0;
}
.header-main .container{
    display: flex;
    align-items: center;
}
.logo{
    height: 126px;
    width: auto;
    margin-top: -20px;
    margin-bottom: -50px;
    z-index: 9;
}
.logo img{
    height: 100%;
    width: 100%
}
#navToggle{
    height: 100%;
    width: 52px;
    cursor: pointer;
    display: none;
}
#navToggle span{
    height: 2px;
    width: 18px;
    background: white;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: relative;
    transition: 0.3s all ease;

    &::before, &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: white;
        transform: translate(0%, -300%);
        transition: 0.3s all ease;
    }

    &::after {
        transform: translate(0%, 300%);
        width: 14px;
    }
}
#searchToggle{
    height: 100%;
    width: 52px;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;

    img{
        height: 18px;
        width: 18px;
    }
}
.search{
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 0 60px;
}
.search-wrapper{
    max-width: 540px;
    min-width: auto;
    width: 100%;
}
.search-form{
    display: flex;
    position: relative;
    background: white;
}
.search__input{
    background: $gray-100;
    border: 0;
    width: 100%;
    outline: none;
    height: 50px;
}
.search__button{
    border: 0;
    background: $gray-1000;
    height: 44px;
    color: white;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 3px;
    font-size: 13px;
    text-transform: uppercase;
    padding: 0 17px;
    margin-top: 3px;
}
.search__button.--model{
    color: $gray-1000;
    background: $yellow-500;
}
.header-nav{
    display: flex;
}
.header-nav .user{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0px 0 0;
    cursor: pointer;
    position: relative;
    flex-direction: column;
}
.header-nav .user.--active .user__title{
    font-weight: 500;
}
.header-nav .user__wrap{
    display: flex;
    flex-direction: column;
    margin-top: -10px;
}
.header-nav .user__title{
    color: $white;
    max-width: 90px;
    text-align: center;
    font-size: 12px;
    line-height: 1;
}
.header-nav .user__text{
    color: $gray-900;
    font-size: 14px;
    line-height: 1;
}
.header-nav .user__icon{
    border-radius: 50%;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
}
.header-nav .user__icon img{
    height: 24px;
    width: 24px;
}
.header-nav .user__dropdown{
    position: absolute;
    margin-top: 10px;
    top: 100%;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 220px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border: 1px solid $gray-300;
    background: $white;
    padding-bottom: 10px;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease opacity;

    &::before{
        content: "";
        height: 12px;
        width: 12px;
        display: inline-flex;
        background: $white;
        border-top: 1px solid $gray-300;
        border-right: 1px solid $gray-300;
        position: relative;
        transform: translateY(-6px) rotate(-45deg);
        margin-left: 38px;
    }
    a{
        background: $white;
        padding: 10px 20px;
        color: $gray-900;
        &:hover, &:active, &:focus{
            background: $gray-100;
        }
    }
}
body.--user-active .header-nav .user__dropdown{
    opacity: 1;
    pointer-events: auto;
}
.header-nav .user img{

}
.header-nav .cart{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0px 0 30px;
    color: $gray-900;
    flex-shrink: 0;
}
.header-nav .cart__icon{
    border-radius: 50%;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    position: relative;
}  
.header-nav .cart__icon img{
    width: 24px;
    height: 24px;
}
.header-nav .cart__number{
    position: absolute;
    background: $red-500;
    color: $white;
    border: 2px solid $gray-1000;
    font-size: 11px;
    font-weight: 500;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    line-height: 1;
    text-align: center;
    top: 24px;
    left: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-nav .cart img{
    
}
.header-nav .cart-wrap{
    color: white;
}
.header-nav .cart-title{
    font-size: 16px;
}
.header-nav .cart.--active .cart-title{
    font-size: 13px;
    line-height: 1;
}
.header-nav .cart-price{
    font-size: 15px;
    font-weight: 500;
}
@media screen and (max-width: 1250px){
    .logo {
        height: 90px;
        flex-shrink: 0;
        margin-top: -35px;
    }
    .header-main{
        padding: 15px 0;
    }
}
@media screen and (max-width: 991px){
    header {
        padding-bottom: 52px;
    }
    .header-main{
        padding: 0;
        height: 52px;
        position: fixed;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        background: $gray-900;
    }
    .header-main .container{
        padding: 0;
        height: 100%;
    }
    .logo {
        height: auto;
        width: auto;
        margin-top: 16px;
        margin-bottom: 0;
        z-index: 9;
    }
    .logo img{
        height: 68px;
        width: auto;
        margin-left: 0px;
    }
    #navToggle, #searchToggle{
        display: flex;
        flex-shrink: 0;
    }
    .search{
        padding: 0;
    }
    .search-wrapper{
        display: none;
        
    }
    body.--search-active{
        overflow-y: hidden;
    }
    body.--search-active .header-main{
        z-index: 15;
    }
    body.--search-active  .search-wrapper{
        display: flex;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-width: 100%;
        box-shadow: 0px 10px 8px -8px rgba(0,0,0,0.05);
        border: 10px solid $gray-900;
        background: $gray-900;

        .search-form{
            width: 100%;
        }
    }
    .header-nav {
        height: 100%;
    }
    .header-nav .user{
        padding: 0;
        margin: 0;
    }
    .header-nav .user__title{
        display: none;
    }
    .header-nav .user__text{
        display: none;
    }
    .header-nav .user__icon{
        margin: 0;
        border-radius: 0;
        background: transparent;
        height: 100%;
        width: 52px;
    }
    .header-nav .cart{
        padding: 0;
        margin: 0;
    }
    .header-nav .cart__icon{
        margin: 0;
        border-radius: 0;
        background: transparent;
        height: 100%;
        width: 52px;
    }
    .header-nav .cart-wrap{
        display: none;
    }
    .header-nav .cart__number{ 
        border: 2px solid $gray-1000;
        top: 25px;
        left: 24px;
    }
    .header-nav .user__dropdown {
        right: -42px;
        left: auto;

        &::before{
            margin-left: 145px;
        }
    }
}
@media screen and (max-width: 991px){
    .header-nav .user__icon{
        display: none;
    }
}
@media screen and (max-width: 600px){
    body.--search-active .search-wrapper .search-form{
        flex-wrap: wrap;
    }
    .search__button{
        width: 50%;
        margin: 0;
    }
}
