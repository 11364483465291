.page-content{
    padding-top: 0;
    margin-top: -40px;
}
.page-content__cell{
    padding-bottom: 60px;
}
.page-content__cell > *:last-child{
    padding-bottom: 0px;
    margin: 0;
}
.page-content .row> *:last-child > .page-content__cell{
    padding-bottom: 0px;
}



.page-content h2{
    font-size: 24px;
    padding-bottom: 20px;
    margin: 0;
    margin-top: 20px;
}
.page-content h3{
    padding-bottom: 20px;
    margin: 0;
    margin-top: 20px;
}
.page-content h4{
    padding-bottom: 20px;
    margin: 0;
    margin-top: 20px;
}
.page-content h5{
    display: block;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 20px;
    margin: 0;
    margin-top: 20px;
}
.page-content h6{
    padding-bottom: 20px;
    margin: 0;
    margin-top: 20px;
}
.page-content .contact-block{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    border: 1px solid $gray-300;
    padding: 0 30px;
    font-size: 18px;
    font-weight: 500;
    color: $gray-900;
    margin-bottom: 20px;
    transition: 0.2s ease box-shadow;

    &:hover, &:active, &:focus{
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    }
}
.page-content p, .page-content strong, .page-content b, .page-content i, .page-content u{
    display: block;
    color: $gray-900;
    margin-bottom: 40px;
    line-height: 1.75;
}
.page-content p strong, .page-content p b, .page-content p i, .page-content p u{
    display: inline-block;
}
.page-content ul{
    margin: 0;
    padding-bottom: 40px;
    padding-left: 20px;
}
.page-content li{
    padding: 8px 0;
    font-weight: 400;
}
.page-content li strong{
    display: inline-block;
}
.page-content table{
    color: $gray-500;
    margin-bottom: 40px;
    line-height: 1.75;

}
.page-content table th, .page-content table td{
    border: 1px solid $gray-300;
    padding: 10px 20px;
    white-space: nowrap;
}

.page-content__cell ul{
    list-style: none;
    margin: 0;
    padding: 0;
    padding-bottom: 40px;
}
.page-content__cell li{
    strong{
        margin: 0;
        color: $gray-900;
    }
}
.page-content__cell h2{
    border-bottom: 1px solid $gray-300;
    padding-bottom: 10px;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 500;
    font-family: $font-family-secondary;
    text-transform: uppercase;
}
.page-content__cell h5{
    padding-bottom: 40px;
}

.page-content__grid{
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3,1fr);
    align-items: flex-start
}
.page-content__gridItem{
    display: flex;
    flex-direction: column;
    padding: 60px 20px;
    border: 1px solid $gray-300;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease box-shadow;

    &:hover, &:active, &:focus{
        box-shadow: 0px 4px 8px rgba(0,0,0,0.08);
    }
}
.page-content__gridItem img{
    height: 66px;
    max-width: 72px;
    display: none;
}
.page-content__gridItem h2{
    color: $gray-900;
    margin-top: 0px;
}
.page-content__gridItem p{
    max-width: 340px;
}
.page-content__gridItem span{

}

@media screen and (max-width: 991px){
    .page-content__grid{
        grid-template-columns: repeat(2,1fr);
        grid-gap: 10px;
    }
}

@media screen and (max-width: 575px){
    .page-content__grid{
        grid-template-columns: repeat(1,1fr);
    }
}
