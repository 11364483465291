.subcategories{
    padding-top: 0;
    padding-bottom: 40px;
}
.subcategories__single{
    padding: 10px 0;
}

.subcategories__link{
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 54px;
    border: 1px solid $gray-1000;
    transition: 0.2s ease box-shadow;
    overflow: hidden;
    background: $white;

    &:hover, &:active, &:focus{
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    }
}
.subcategories__img{
 

    img{
        object-fit: contain;
        height: 46px;
        width: 46px;
    }
}
.subcategories__link img{

}
.subcategories__title{
    font-size: 14px;
    color: $gray-900;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
    padding-left: 15px;
}
@media screen and (max-width: 575px){
    .subcategories{
        overflow-x: auto;
        padding: 0;
    }
    .subcategories .row{
        flex-wrap: nowrap;
        -ms-overflow-style: none; 
        scrollbar-width: none; 
        overflow-x: scroll;
    }
    .subcategories .row::-webkit-scrollbar { 
        display: none;
    }
    .subcategories__single{
        padding: 0;
    }
    .subcategories__link{
        padding: 0 10px;
        height: 56px;
    }
    .subcategories__title{
        padding-left: 10px;
    }
}