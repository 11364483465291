.products-sort{
    display: flex;
    border-bottom: 1px solid $gray-300;
    margin-bottom: 20px;
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}
.products-sort::-webkit-scrollbar { 
    display: none;
}
.products-sort form{
    display: flex;

}
.products-sort__single{
    display: flex;
    position: relative;
    line-height: 1;
    cursor: pointer;
    margin-right: 40px;
    flex-shrink: 0;
}

.products-sort__single input{
    opacity: 0;
    position: absolute;
    height: 0;
    width: 0;
}
.products-sort__single label{
    display: flex;
    font-weight: 500;
    font-size: 14px;
    padding: 15px 0;
    text-transform: uppercase;
    color: $gray-500;
    cursor: pointer;
}
.products-sort__single input:checked + label{
    color: $gray-900;
}
.products-sort__single input:checked + label::after{
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    background: $gray-900;
}
@media screen and (max-width: 991px){

}