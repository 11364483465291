.autoComplete_wrapper {
    width: 100%;
}

.autoComplete_wrapper>input::placeholder {

}


.autoComplete_wrapper>ul {
    position: absolute;
    overflow-y: auto;
    box-sizing: border-box;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    z-index: 1;
    list-style: none;
    border-radius: 0;
    background-color: $white;

    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    outline: 0;
    transition: opacity .15s ease-in-out;
}

.autoComplete_wrapper>ul:empty, .autoComplete_wrapper>ul[hidden] {
    display: block;
    opacity: 0;
    transform: scale(0)
}

.autoComplete_wrapper>ul>li {
    margin: 0;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    text-align: left;
    font-size: 16px;
    color: $gray-900;
    border-radius: 0;
    background-color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .2s ease
}

.autoComplete_wrapper>ul>li mark {
    background-color: transparent;
    color: $green-500;
    font-weight: 500;
    padding: 0;
}

.autoComplete_wrapper>ul>li:hover {
    cursor: pointer;
    background-color: rgba(255, 122, 122, .15)
}

.autoComplete_wrapper>ul>li[aria-selected=true] {
    background-color: rgba(255, 122, 122, .15)
}
