.btn{
    color: $white;
    text-transform: uppercase;
    font-size: 14px;
    min-height: 54px;
    height: 100%;
    padding: 0rem 30px;
    background: $gray-900;
    position: relative;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
    white-space: normal;
    transition: 0.2s ease background;

    &:hover, &:active, &:focus{
        color: $white;
        box-shadow: none;
        background: $black;
    }
}
.btn img{
    margin-left: 15px;
    width: 6px;
    height: 10px;
}


.btn.--green{
    background: $green-500;

    &:hover, &:active, &:focus{
        background: $black;
    }
}
.btn.--green img{
    margin-left: 0px;
    margin-right: 15px;
    width: 18px;
    height: 20px;
}

.btn.--cart{
    background: $yellow-500;
    color: $gray-1000;
    width: auto;
    height: 56px;

    &:hover, &:active, &:focus{
        background: $yellow-500;
        color: $gray-1000;

        img{
            margin-left: 20px;
        }
    }
}
.btn.--cart img{
    margin-left: 15px;
    transition: 0.2s ease margin;
    transform: rotate(-90deg);
    height: 10px;
    width: 10px;
}

.btn.--cart-dark{
    /*background: transparent;
    color: $gray-900;
    border: 1px solid $gray-300;*/
    width: 100%;
    height: 62px;
}

.btn-ghost{
    background: transparent;
    color: $gray-1000;
    border: 1px solid $gray-1000;
}

.btn.--fullWidth{
    width: 100%;
}