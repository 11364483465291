@charset 'utf-8';

/* ========================================================================== */
/* BOOTSTRAP */
/* ========================================================================== */


// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/grid";

/* ========================================================================== */
/* VARIABLES */
/* ========================================================================== */

@import "variables/_colors.scss";
@import "variables/_fonts.scss";

/* ========================================================================== */
/* COMPONENTS */
/* ========================================================================== */

@import "components/_article.scss";
@import "components/_autocomplete.scss";
@import "components/_blog.scss";
@import "components/_breadcrumbs.scss";
@import "components/_btn.scss";
@import "components/_carousel.scss";
@import "components/_cart-content.scss";
@import "components/_cart-heading.scss";
@import "components/_categories.scss";
@import "components/_contact.scss";
@import "components/_cookiebar.scss";
@import "components/_filter.scss";
@import "components/_footer.scss";
@import "components/_form.scss";
@import "components/_header.scss";
@import "components/_lightbox.scss";
@import "components/_modal.scss";
@import "components/_nav.scss";
@import "components/_news-section.scss";
@import "components/_news-single.scss";
@import "components/_order-history.scss";
@import "components/_page-content.scss";
@import "components/_page-heading.scss";
@import "components/_page.scss";
@import "components/_pagination.scss";
@import "components/_points.scss";
@import "components/_product-card.scss";
@import "components/_product-detail.scss";
@import "components/_products-footer.scss";
@import "components/_products-grid.scss";
@import "components/_products-heading.scss";
@import "components/_products-sort.scss";
@import "components/_range-slider.scss";
@import "components/_searchAutocomplete.scss";
@import "components/_slider.scss";
@import "components/_social.scss";
@import "components/_stepper.scss";
@import "components/_subcategories.scss";
@import "components/_tag.scss";

body{
    background :$white;
    color: $gray-1000;
    font-family: $font-family-primary;
    overflow-x: hidden;
    font-size: 14px;
}
body.cart #navToggle{
    display: none;
}
a{
    color: $gray-1000;
    text-decoration: none;

    &:hover, &:active, &:focus{
        color: $gray-900;
    }
}
img, svg {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}
strong{
    font-weight: 600;
}
h1{
    font-size: 36px;
}
.container{
    max-width: 1340px;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    margin: 0 auto;
}
.row {
    margin-right: -10px;
    margin-left: -10px;
}
.row>* {
    padding-right: 10px;
    padding-left: 10px;
}
@media screen and (max-width: 575px){
    .container{
        padding-right: 10px;
        padding-left: 10px;
    }
    .row {
        margin-right: -5px;
        margin-left: -5px;
    }
    .row>* {
        padding-right: 5px;
        padding-left: 5px;
    }
}

main{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F7F7F7 100%);
    padding-bottom: 60px;
}
section{
    padding: 40px 0;
}
section h1{
    font-size: 32px;
    font-weight: 500;
    padding-bottom: 40px;
    margin: 0;
    color: $gray-900;
    font-family: $font-family-secondary;
    text-transform: uppercase;
}
@media screen and (max-width: 575px){
    section{
        padding: 20px 0;
    }
    section h1{
        font-size: 24px;
        font-weight: 500;
        padding-bottom: 20px;
    }
}
section.products{
    padding: 80px 0;
}
section.products.--special{
    background: #F1F1F1;
    background-image: url("../img/bg-stripes.png");
    background-position: bottom left;
    background-repeat: repeat-x;
    margin-bottom: -60px;
}
section.products h1{
    padding-bottom: 20px;
    text-align: center;
}




#darkBackdrop{
    display: flex;
    opacity: 0;
    pointer-events: none;
    background: rgba(0,0,0,0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
@media screen and (max-width: 991px){
    #darkBackdrop{

    }
    body.--nav-active #darkBackdrop{
        opacity: 1;
        pointer-events: auto;
    }
    body.--search-active #darkBackdrop{
        opacity: 1;
        pointer-events: auto;
    }
    body.--filter-active #darkBackdrop{
        opacity: 1;
        pointer-events: auto;
    }
}


.logout-wrap{
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.delete-account{
    margin-top: 60px;
}
.delete-account h2{
    margin-bottom: 10px;
}

.category-heading{
    background: $gray-100;
    background-image: url("../img/bg-stripes.png");
    background-position: bottom left;
    background-repeat: repeat-x;
}
.category-heading .page-heading{
    padding-bottom: 30px;
}

.page-summary{

}

.katalog-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
}
@media screen and (max-width: 991px){
    .katalog-grid{
    grid-template-columns: 1fr 1fr;
}}
@media screen and (max-width: 575px){
    .katalog-grid{
    grid-template-columns: 1fr;
}}
.katalog-col{
    display: flex;
    flex-direction: column;
}
.katalog-title{
    font-family: $font-family-secondary;
    text-transform: uppercase;
    font-weight: 500;
    border-bottom: 3px solid $gray-300;
    font-size: 24px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.katalog-item{
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray-300;
    width: 100%;
    min-height: 50px;
}
.katalog-img-wrap{

}
.katalog-img{

}
.katalog-name{
    margin-left: 20px;
    flex-grow: 1;
    text-align: left;
}
.katalog-qty{
    background: $gray-100;
    border-radius: 10px;
    font-size: 13px;
    padding: 0 5px;
}
.cart-aside__codeInner{
    display: flex;
}
.cart-aside__codeInner .btn{
    min-height: 50px;
}

#register-form .form-group.--checkbox>label{
    max-width: 100%;
}

.cart-content__form details{
    background: white;
    margin-bottom: 40px;
}
.cart-content__form summary{
    display: flex;
    align-items: center;
    background: white;
    padding: 0 20px;
    height: 54px;
    margin-bottom: 0px;
    border: 1px solid #e5e5e5;
    text-transform: uppercase;
    font-size: 14px;
    color: $gray-1000;
    font-weight: 500;
}
.cart-content__form details .form-group{
    margin: 0;
    padding: 20px;
    border: 1px solid #e5e5e5;
    margin-top: -1px;
}
.products-bottom{
    display: flex;
    align-items:center;
    width: 100%;
    margin-top: 20px;
    justify-content: center;
}

.product-detail__subtitle{
    text-transform: uppercase;
    font-size: 14px;
    padding-bottom: 5px;
    font-weight: 500;
}
.product-detail__params{
    width: 100%;
    background: $gray-100;    
    margin-bottom: 40px;
}
.product-detail__params td{
    border-bottom: 1px solid $gray-300;
    padding: 10px;
}


#cartSummaryToggle{
    display: none;
}
#cartSummaryClose{
    display: none;
}

@media screen and (max-width: 575px){
    #cartSummaryToggle{
        display: flex;
        background: $yellow-500;
        color: $gray-1000;
        height: 54px;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    #cartSummaryClose{
        display: flex;
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        background: $gray-100;
        border-radius: 50%;

        img{
            height: 14px;
        }
    }
}

.product-card .btn img{
    margin: 0;
    margin-right: 10px;
    height: 16px;
    width: 16px;
}


.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    margin: 20px 0;
}
.resp-iframe, .resp-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}


.modal__container.--cart {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}

.modal__container.--cart .modal__image{
  padding: 0 30px;
}

.modal__container.--cart .modal__content {
    padding: 40px 30px 40px 30px;
    align-items: center;
}
.btn.--cart-dark{
  height: 56px;
}


.katalog-title{
    display: flex;
    justify-content: space-between;
    align-items:center;
}


.slider-item-ratio .slider-container.product-detail__carousel>*>* {
    object-fit: contain;
}
.slider-item-ratio .slider-container>*>* {
    object-fit: contain;
}

.modal__container.--cart .modal__image{
    padding-top: 30px;
  }
.modal__container.--cart  .modal__image img {
      height: auto;
  }

 
.subcategories__img{
    flex-shrink: 0;
}
.subcategories__single, .subcategories__link{
    min-height: 54px;
    height: 100%
}
.products-grid .product-card{
    height: auto;
}


.product-detail__warranty{
    display: flex;
  
  }
  .product-detail__code{
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .product-detail__status{
  margin-bottom: 40px;
    padding: 15px 10px;
    display: flex;
    border: 2px solid #399841;
    background: rgba(57, 152, 65,0.05);
    font-weight: 500;
  }
  .product-detail__status.--used{
    border: 2px solid #FDDB01;
    background: rgba(253, 219, 1,0.05);
  }
  .product-detail__status.--repas{
    border: 2px solid #262626;
    background: rgba(23, 23, 23,0.05);
  }