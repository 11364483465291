nav{
    background: $yellow-500;
    background-image: url("../img/bg-nav.png");
    background-position: bottom left;
    background-repeat: repeat;
    position: relative;
    padding: 40px 0;
}
nav.disabled .nav-item{
    pointer-events: none;
}
nav .container{
    display: flex;
}
#mainNav form{
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-gap: 20px;
    width: 100%;
}
.nav-item{
    display: flex;
    flex-grow: 1;
    justify-content: center;
}
.nav-item select{
    width: 100%;
    height: 58px;
    font-size: 15px;
    border: 0;
}
.nav-item option{
    font-size: 15px;
}
.nav-item-backdrop{
    display: none;
}
.nav-link, .nav-linkDesktop{
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    color: $gray-900;
    text-align: center;
    flex-grow: 1;
}
.nav-link img, .nav-linkDesktop img{
    margin-left: 10px;
    width: 8px;
    height: 5px;
}
.nav-linkDesktop + .nav-link{
    display: none;
}


.nav-submenu{
    position: absolute;
    left: 0;
    width: 100%;
    top: 100%;
    z-index: 3;
    background: $white;    
    padding: 20px 0;
}
.nav-submenu .row{
    flex-grow: 1;
}
.nav-submenu__single{
    display: flex;
    flex-direction: row;
    padding: 40px 0;
    align-items: flex-start;
}
.nav-submenu__single.--all{
    display: none;
}
.nav-submenu__single img{
    width: 80px;
}
.nav-submenu__single ul{
    list-style: none;
    margin: 0;
    padding-left: 40px;
    padding-bottom: 20px;
}
.nav-submenu__single li{
    padding: 0;
    display: flex;
}
.nav-submenu__title{
    color: $gray-900;
    font-weight: 500;
    padding-bottom: 10px;
}
.nav-submenu__link{
    color: $gray-500;
}

@media screen and (min-width: 992px){
    .nav-item-backdrop{
        justify-content: center;
        align-items: flex-start;
        background: rgba(0,0,0,0.2);
        display: flex;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        transition: 0.2s ease opacity;
        transition-delay: 0.3s;
    }
    .nav-item.expandable:hover ~ .nav-item-backdrop{
        opacity: 1;
        pointer-events: auto;
        animation: delay-pointer-events 0.5s linear;
    }
    .nav-item:hover .nav-link, .nav-item:hover .nav-linkDesktop {
        background: $white;
    }
    .nav-submenu{
        opacity: 0;
        pointer-events: none;
        transition: 0.2s ease opacity;
        transition-delay: 0.3s;
    }
    .nav-item.expandable:hover .nav-submenu{
        opacity: 1;
        pointer-events: auto;
        animation: delay-pointer-events 0.5s linear;
    }
    @keyframes delay-pointer-events {
        0%, 99% {
            pointer-events: none;  
          }  
    }
}
@media screen and (max-width: 991px){
    nav{
        background: $yellow-500;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 15;
        bottom: 0;
        overflow-y: auto;
        width: 260px;
        transform: translateX(-100%);
        transition: 0.2s ease transform;
        height: 100%;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        content-visibility: hidden;
    }
    #mainNav form{
        display: flex;
        flex-direction: column;
        padding: 0 15px;
    }
    body.--nav-active{
        overflow-y: hidden;
    }
    body.--nav-active nav{
        transform: translateX(0%);  
        content-visibility: visible;
    }
    nav .container{
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .nav-item{
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }
    .nav-linkDesktop + .nav-link{
        display: flex;
        width: 100%;
        flex-grow: 1;
        justify-content: space-between;
    }
    .nav-link {
        justify-content: flex-start;
        width: 100%;
        display: flex;
    }
    .nav-linkDesktop{
        display: none;
    }
    .nav-submenu{
        position: relative;
        flex-direction: column;
        background: $gray-100;
        padding: 10px 0;
        display: none;
    }
    .nav-submenu__single{
        padding: 0;
        flex-grow: 1;
        width: 100%;
    }
    .nav-submenu__single.--all{
        display: flex;
        .nav-submenu__title{
            font-weight: 500;
        }
    }
    .nav-submenu .row {
        margin-right: 0px;
        margin-left: 0px;
    }
    .nav-submenu .row>* {
        padding-right: 0px;
        padding-left: 0px;
    }
    .nav-submenu__single img{
        display: none;
    }
    .nav-submenu__title{
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-weight: 400;
    }
    .nav-submenu__link{
        display: none;
    }
    .nav-submenu__single ul{
        padding: 0;
        width: 100%;
    }
    .nav-submenu.--active{
        display: flex;
    }
}
