.products-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0 20px 0;
}
.products-footer .btn{
    margin-bottom: 40px;
    
}